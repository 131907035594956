<template>
  <div class="px-0">
    <v-row 
      style="background-color: #FF3700; height: 6.5rem;"
      align-content="center"
      justify="center"
      class="mx-0 my-0 hidden-sm-and-down"
    >
      <v-col 
        cols="12" 
        md="4" 
        class="mt-1" 
        style="overflow-y: hidden!important; max-height: 100%!important;">
        <router-link :to="'construfacil'">
        <v-img
          :src="require('@/assets/CONSTRUFACIL-LOGO.png')"
          contain
          width="100%"
          height="100%"
          style="overflow: hidden!important;"
        />
        </router-link>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="px-10 py-0 "
        align-self="center"
      >
        <ais-instant-search
          :search-client="searchClient"
          index-name="inventario_index"
        >
          <ais-configure :filters="`id_sucursal=${sucursalId}`" />
          <ais-search-box>
            <template v-slot="{ currentRefinement, isSearchStalled, refine }">
              <v-text-field
                :value="currentRefinement"
                v-model="search"
                :loading="isSearchStalled"
                solo
                label="Buscar..."
                class="mt-8"
                append-icon="mdi-magnify"
                clearable
                @input="refine(search)"
              />
            </template>
          </ais-search-box>
          <ais-hits
            v-if="search !== null && search.length > 0"
            :class-names="{
              'ais-Hits': 'results-container'
            }"
          >
            <template v-slot="{ items }">
              <v-card
                v-if="items.length"
                class="mt-0"
                elevation="16"
                max-height="300"
                max-width="380"
                min-width="300"
              >
                <v-list
                  max-height="300"
                  class="overflow-y-auto"
                >
                  <v-list-item
                    v-for="item in items"
                    :key="item.objectID"
                    @click="goToProducts(item)"      
                  >
                    <v-list-item-avatar tile>
                      <v-img :src="defaultImage(item.imagenes_producto[0])" />
                    </v-list-item-avatar>
                    <v-list-item-content class="text-h6">
                      {{ item.nombre_producto }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card v-else>
                <v-card-text>
                  No se encontraron coincidencias
                </v-card-text>
              </v-card>
            </template>
          </ais-hits>
        </ais-instant-search>
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="px-10 py-0 text-center"
        align-self="center"
      >
        <v-text-field
          v-model="newUserCP"
          prepend-icon="mdi-map-marker"
          append-icon="mdi-crosshairs-gps"
          solo
          clearable
          :label="infoUserAddress"
          type="text"
          color="secondary"
          class="pruebaHint mt-8"
          ref="autocomplete"
          :hint="sucursalText"
          persistent-hint
          @click:append="newCPUser"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div style="height: 30px; width: 100%; background-color: #2D4761;"></div>

    <v-app-bar
      id="home-app-bar"
      class="px-0"
      app
      flat
      absolute
      color="white"
      elevation="1"
      height="60"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />

      <v-container class="py-0 px-0">
        <v-row align="center">
          <v-btn
            class="hidden-sm-and-down mr-4"
            :to="{name: menu[0]}"
            text
            active-class="primary"
            :small="true"
          >
            {{ this.menu[0] }}
          </v-btn>

          <v-btn
            class="hidden-sm-and-down"
            text
            :to="{name: menu[1]}"
            active-class="primary"
            :small="true"
          >
            {{ this.menu[1] }}
          </v-btn>
          
          <v-btn
            class="hidden-sm-and-down"
            text
            :to="{name: menu[2]}"
            active-class="primary"
            :small="true"
          >
            {{ this.menu[2] }}
          </v-btn>

          <div class="hidden-sm-and-down">
            <menu-desplegable
              class="ma-2"
              :menuData="menuCuenta"
              :ButtonName="'Mi Cuenta'"
            />
          </div>

          <v-btn
            class="hidden-sm-and-down"
            text
            :to="{name: menu[3]}"
            active-class="primary"
            :small="true"
          >
            {{ this.menu[3] }}
          </v-btn>
          <v-spacer />
  
          <login-card
            class="hidden-sm-and-down"
            v-if="!accessToken"
          />
          <signup-card
            class="hidden-sm-and-down"
            v-if="!accessToken"
          />

          <v-btn
            class="mr-4 hidden-sm-and-down"
            text
            v-if="accessToken && isAuth"
            :small="true"
            :to="{name: 'DashBoard'}"
            active-class="primary"
          >
            <v-icon left>mdi-chart-line</v-icon>
            <div class="hidden-md-only">dashBoard</div>
          </v-btn>

          <v-btn
            class="mr-4 hidden-sm-and-down"
            text
            v-if="accessToken"
            @click="logoutUser"
            :small="true"
          >
            <v-icon left> mdi-logout</v-icon>
            <div class="hidden-md-only">Cerrar Sesión</div>
          </v-btn>

          <v-btn
            text
            v-if="showingCart"
            :to="{name: 'Carrito'}"
            :small="true"
          >
            <v-badge
              :content="productsCount"
              :value="productsCount"
              color="primary"
              overlap
            >
              <v-icon color="primary">
                mdi-cart
              </v-icon>
            </v-badge>
          </v-btn>

        </v-row>
      </v-container>
    </v-app-bar>
    <home-drawer
      v-model="drawer"
      :items="menu"
      :desplegableItems="menuCuenta"
    />
  </div>
</template>

<script>
  import {
    mapGetters,
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  import { axiosBase } from '../api_utils/axios-base.js'
  import { inventoryMixin } from '@/mixins/inventory.js'
  import algoliasearch from 'algoliasearch/lite';
  export default {
    components: {
      MenuDesplegable: () => import('@/components/utils/MenuDesplegable.vue'),
      SignupCard: () => import('@/components/utils/accountDialogs/SignupCard.vue'),
      LoginCard: () => import('@/components/utils/accountDialogs/LoginCard.vue'),
      HomeDrawer: () => import('./Drawer'),
      SearchResults: () => import('./utils/SearchResults.vue')
    },
    mixins: [inventoryMixin],
    data () {
      return {
        drawer: null,
        newUserCP: null,
        searchX: 0,
        searchY: 0,
        itemClicked: false,
        searchClient: algoliasearch(
          '6F9H1XRC2M',
          '9e0f15d4b967e4ea377e89bf53b25d12'
        ),
        menu: [
          'Construfácil',
          'Productos',
          'Marcas',
          'Directorio de tiendas'
        ],
        miCuenta: [
          {
            option: "Mis pedidos",
            user_type: ["Cliente"]
          },
          {
            option: "Mis datos",
            user_type: ["Cliente", "Distribuidor"]
          },
          {
            option: "Mis pagos",
            user_type: ["Cliente"]
          },
          {
            option: "Lista de deseos",
            user_type: ["Cliente"]
          },
          {
            option: "Descuentos",
            user_type: ["Distribuidor"]
          },
          {
            option: "Tabulador de Envíos",
            user_type: ["Distribuidor"]
          },
          {
            option: "Actualizar inventarios",
            user_type: ["Distribuidor"]
          },
          {
            option: "Precios de productos",
            user_type: ["Distribuidor"]
          },
          {
            option: "Distribuidores",
            user_type: ["Tyasa"]
          },
          {
            option: "Administrar templates",
            user_type: ["Tyasa"]
          },
        ]
      }
    },
    computed: {
      ...mapState('cart', ['productsCount']),
      ...mapState('usuario', ['accessToken', 'userAddress']),
      ...mapState('products', ['products', 'sucursalId', 'distributorId']),
      ...mapGetters('usuario', ['isAuth', 'esDistribuidor', 'esTyasa', 'esCliente', 'showingCart']),
      ...mapGetters('products', ['getSucursalName']),
      userType() {
        return this.esDistribuidor 
          ? "Distribuidor" 
          : (this.esTyasa 
            ? "Tyasa" 
            : "Cliente")
      },
      menuCuenta() {
        return this.miCuenta.filter(element => {
          if (element.user_type.some(type => type === this.userType)) {
            return element
          }
        });
      },
      infoUserAddress() {
        return this.userAddress?.codigo_postal 
      },
      sucursalText() {
        return this.getSucursalName ? `Tu tienda: ${this.getSucursalName}` : ""
      },
    },
    methods: {
      ...mapMutations(['toggleDrawer']),
      ...mapMutations('products', ['setTotalItems', 'setLastSearch']),
      ...mapActions('usuario', ['logout']),
      ...mapActions('products', ['setProductSearch', 'setIsSearch']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
      logoutUser() {
        this.logout() 
      },
      newCPUser() {
        localStorage.setItem("cp", this.newUserCP)
      },
      goToSearch() {
        this.searchProducts()
      },
      getPositionXY() {
        const searchInput = document.getElementById('search')
        const rect = searchInput.getBoundingClientRect();
        this.searchX = rect.x
        this.searchY = rect.y - 30
      },
      async searchProducts() {
        let page = 1
        this.searching = true
        const urlSearch = `/inventarios/api/inventarios-nombre-producto/?id_sucursal=${this.sucursalId}&nombre_producto=${this.search}&page=${page}`
        if(this.search.length > 2) {
          let response = await axiosBase.get(urlSearch)
          let responseData = await response.data
          const results = responseData.results
          const resultsWithPrice = []
          results.forEach(fp => {
          if (fp.producto.precios.length === 0) {
            fp.producto.precio = 1000.00
          } else {
            const price = fp.producto.precios.find(pr => pr.distribuidor === this.distributorId)
            if (price) {
              const images = fp.producto.imagenes
              const webpImages = images.filter(img => img.imagen.includes('.webp?'))
              const pngImages = images.filter(img => img.imagen.includes('.png?'))
              
              fp.producto.imagenes = webpImages ? this.shuffleImages(webpImages) : []
              fp.producto.imagenesPNG = pngImages ? this.shuffleImages(pngImages) : []

              fp.producto.precio = price.precio_distribuidor
              const productWithPrice = {...fp}
              resultsWithPrice.push(productWithPrice)
            }
          }
        })
          this.setTotalItems(resultsWithPrice.length)
          this.setLastSearch(this.search)
          //console.log('Resultados busqueda: ', results);
          this.filteredProducts = resultsWithPrice
          this.setProductSearch(resultsWithPrice)
          this.searching = false
        }
      },
      shuffleImages(arraySrc) {
        const shuffledList = arraySrc.sort(() => Math.random() - 0.5)
        return shuffledList
      },
      close() {
        setTimeout(() => {
          if (!this.itemClicked) {
            this.clearText()
          }
        }, 400)
      },
      setClicked() {
        this.itemClicked = true
      },
      clear() {
        this.itemClicked = false
        this.clearText()
      },
      goToProducts({ objectID: id, nombre_producto: nameProduct, categoria_producto: category }) {
        // console.log('id:', id, 'nombre_producto:', nameProduct, 'categoria_producto:', category);
        this.setIsSearch(true)
        this.setLastSearch(nameProduct)
        this.$router.push({
          name: 'Productos', 
          query: {
            filtro: category,
            id: id,
            t: new Date().getTime()
          }
        })
        this.search = ''
      }
    },
    mounted() {
      //new google.maps.places.AutoComplete(
      //  this.$refs["autocomplete"]
      //)
      //this.getPositionXY()
      this.allProducts = this.products
    }
  }
</script>

<style>
  .v-toolbar, .v-toolbar--absolute {
    position: relative !important;
  }
  p {
    font-family: "Montserrat", sans-serif;
    color: #2D4761;
  }
  .pruebaHint .v-messages {
    color: rgba(0,0,0,.54) !important;
    font-weight: bold;
    font-size: 0.85rem;
  }
  .results-container {
    position: absolute;
    top: 1;
    z-index: 100;
  }
</style>
