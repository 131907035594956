export const inventoryMixin = {
  data() {
    return {
      responsePage: 1,
      search: '',
      allProducts: [],
      filteredProducts: [],
      searching: false,
    }
  },
  methods: {
    calculatePaginateLimit(arrayProductsLength, perPage) {
      return (arrayProductsLength % perPage) > 0 
        ? parseInt(arrayProductsLength / perPage) + 1
        : parseInt(arrayProductsLength / perPage)
    },
    onPageChange(toPage, fromPage) {
      if (toPage === this.calculatePaginateLimit()) {
        this.responsePage++
        //this.getPaginatedProducts(this.responsePage)
      }
    },
    filterByName() {
      const searchLower = this.search.toLowerCase()
      const filter = this.allProducts.filter(inventory => {
        const productNameLower = inventory.producto.nombre_producto.toLowerCase()
        if (productNameLower.includes(searchLower)) {
          return inventory
        }
      })
      this.filteredProducts = filter
    },
    clearText() {
      this.search = ''
      this.filteredProducts = []
      //this.filterByName()
    },
    defaultImage(image) {
      return image !== undefined ? image.imagen : require('@/assets/image-not-found.png')
    },
  }
}
